// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/backgrounds/login.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".AcceptInviteWrapper{display:flex;flex-direction:column;width:100%;height:100%;background-image:linear-gradient(#FFFFFF90, #FFFFFF),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:center;background-size:cover;background-repeat:no-repeat;background-color:#fff}.AcceptInviteWrapper .AcceptInviteLogo{display:flex;align-items:center;width:100%;height:150px;margin-left:10%;font-size:35px}.AcceptInviteWrapper .AcceptInviteContent{display:flex;flex-direction:column;justify-content:center;height:calc(100% - 150px);width:100%;margin-left:10%}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteWelcome{font-size:20px;font-weight:700;color:#333}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteSubtitle{font-size:12px;color:#333}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteLogin{font-size:12px;margin-top:5px;display:flex;justify-content:flex-end}.AcceptInviteWrapper .AcceptInviteContent .isLink{cursor:pointer}.AcceptInviteWrapper .AcceptInviteContent .isLink:hover{color:#a86206}.AcceptInviteWrapper .AcceptInviteContent .AcceptInviteButton{margin-top:30px;width:100%}.AcceptInviteWrapper .AcceptInviteContent form{margin-top:20px;width:350px}.AcceptInviteWrapper .AcceptInviteContent form em{color:#a86206;font-weight:700}.AcceptInviteWrapper.__isMobile{padding:0 20px}.AcceptInviteWrapper.__isMobile .AcceptInviteLogo{margin-left:0}.AcceptInviteWrapper.__isMobile .AcceptInviteLogo,.AcceptInviteWrapper.__isMobile .AcceptInviteContent{margin-left:0}.AcceptInviteWrapper.__isMobile form{width:100%}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
