/*
 *
 * Accept Invite
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Icon } from 'components';
import Strings from 'utils/strings';
import { setLoader, setUser, setToken } from 'store/actions';
import { Input, Button, Form } from 'antd';
import { API, Endpoints } from 'utils/api';
import { push } from 'connected-react-router';
import './styles.scss';
import { toast } from 'utils/utils';

export class AcceptInvite extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isMobile: window.innerWidth <= 768,
			password: '',
			confirmPassword: '',
		};

		this.acceptInvite = this.acceptInvite.bind(this);
		this.failedInvite = this.failedInvite.bind(this);
		this.handleResize = this.handleResize.bind(this);
		window.addEventListener('resize', this.handleResize);
	}

	async componentDidMount() {
		const { match, dispatch } = this.props;

		if (!match?.params?.id || !match?.params?.code) {
			dispatch(push('/'));
		}

		dispatch(setLoader(true));

		try {
			const response = await API.get({ url: Endpoints.uriStaff(`invite/${match?.params?.id}/code/${match.params.code}`) });

			if (response.ok) {
				const { object } = response.data.results;
				if (!object) this.setState({ expired: true });

				const { staff, from } = object;

				this.setState({ staff, from });
			} else
				toast.error(response.data.message);
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	handleResize() {
		this.setState({ isMobile: window.innerWidth <= 768 });
	}

	get passwordIsValid() {
		const { password, confirmPassword } = this.state;

		if (password !== confirmPassword) {
			toast.warning(Strings.authentication.noMatchPassword);
			return false;
		}

		return true;
	}

	goToLogin(e: any) {
		const { dispatch } = this.props;

		e.preventDefault();
		dispatch(push('/login'));
	}

	acceptInvite(e: any) {
		const { staff, password } = this.state;
		const { match, dispatch } = this.props;

		// e.preventDefault();
		console.log('e', e)

		if (!staff?.confirmed && e.password !== e.confirmPassword) {
			toast.error(Strings.errors.passwordsDontMatch)
			return;
		}

		const body = {
			invite: match?.params?.id,
			invitationCode: match?.params?.code,
			password,
		} as any;

		// api call
		API.post({ url: Endpoints.uriStaff('confirm'), data: body }).then(response => {
			console.log(response)

			if (response.ok) {
				const { staff, token } = response.data.results;
				dispatch(setUser({ ...staff }));
				dispatch(setToken(token));
				dispatch(push('/'));
			}
		})
	}

	failedInvite(errorInfo: any) {
		toast.error(Strings.errors.pleaseFillFormCorrectly);
	}

	renderForm() {
		const { isMobile, password, confirmPassword, staff } = this.state;
		const { user } = this.props;

		return (
			<div className={`AcceptInviteWrapper${isMobile ? ' __isMobile' : ''}`}>
				<div className='AcceptInviteLogo'>
					{/* <img className="Logo" alt="Logo" src={logo} /> */}
					<span style={{ color: '#333' }}>Portfolio Backoffice</span> {/* Temporary */}
				</div>
				<div className='AcceptInviteContent'>
					{this.state.expired ? (
						<>
							<div className="ExpiredInviteLabel">
								{Strings.acceptInvite.expired}
								{!user && <a href="/login" onClick={this.goToLogin} className="goToLogin">
									{Strings.authentication.goBackToLogin}
								</a>}
							</div>
						</>
					) : (
						<>
							<span className="AcceptInviteWelcome">
								{Strings.formatString(
									Strings.acceptInvite.title,
									(this.state.staff && this.state.staff.name) || '',
									(this.state.from && this.state.from.name) || '',
									'SROS',
									(this.state.staff && this.state.staff.role) || '',
								)}
							</span>
							<span className="AcceptInviteSubtitle">{Strings.authentication.acceptSubtitle}</span>
							<Form name="recover" onFinish={this.acceptInvite} onFinishFailed={this.failedInvite}>
								<Form.Item
									name="password"
									rules={[{ required: true, message: Strings.errors.fillPassword }]}
								>
									<Input.Password
										value={password}
										onChange={e => this.setState({ password: e.target.value })}
										placeholder={Strings.authentication.newPassword}
										prefix={<Icon name="lock m10r" />}
										visibilityToggle
									/>
								</Form.Item>
								{!staff?.confirmed && <Form.Item
									name="confirmPassword"
									rules={[{ required: true, message: Strings.errors.fillPassword }]}
								>
									<Input.Password
										value={confirmPassword}
										onChange={e => this.setState({ confirmPassword: e.target.value })}
										placeholder={Strings.authentication.confirmPassword}
										prefix={<Icon name="lock m10r" />}
										visibilityToggle
									/>
								</Form.Item>}
								<Button
									type="primary"
									htmlType="submit"
									className="AcceptInviteButton">
									{Strings.generic.confirm}
								</Button>
							</Form>
						</>
					)}
				</div>
			</div>
		);
	}

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.authentication.confirmAccount}</title>
					<meta name='description' content='Description of AcceptInvite' />
				</Helmet>

				{this.renderForm()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
});

export default connect(mapStateToProps)(AcceptInvite);
