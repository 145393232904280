// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error500_Meanwhile_Container{display:flex;flex-direction:column;align-items:center;margin-top:20px}.Error500_Meanwhile_Container div:last-child{margin-top:10px}.Error500_Meanwhile_Container .Error500_Option{display:flex;align-items:center;cursor:pointer}.Error500_Meanwhile_Container .Error500_Option:hover{color:#a86206}.Error500_Meanwhile_Container .Error500_Option span,.Error500_Meanwhile_Container .Error500_Option em{font-weight:700}@media screen and (max-width: 768px){.ant-result{padding:0 !important}}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
