/*
 *
 * Dashboard
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { ContentWrapper, Icon } from "components";
import { Row, Col, Card } from "antd";
import {
  ResponsiveContainer,
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
} from "recharts";
import moment from "moment";
import {
  setTitle,
  setBreadcrumb,
  delayedDispatch,
  updateCrumb,
} from "store/actions";
import Strings from "utils/strings";
import "./styles.scss";
// @ts-ignore
import variables from "styles/variables.scss";

const dummyData = [
  { date: "Jan 04", price: 105.35 },
  { date: "Jan 05", price: 102.71 },
  { date: "Jan 06", price: 100.7 },
  { date: "Jan 07", price: 96.45 },
  { date: "Jan 08", price: 96.96 },
  { date: "Jan 11", price: 98.53 },
  { date: "Jan 12", price: 99.96 },
  { date: "Jan 13", price: 97.39 },
  { date: "Jan 14", price: 99.52 },
  { date: "Jan 15", price: 97.13 },
  { date: "Jan 19", price: 96.66 },
  { date: "Jan 20", price: 96.79 },
  { date: "Jan 21", price: 96.3 },
  { date: "Jan 22", price: 101.42 },
  { date: "Jan 25", price: 99.44 },
  { date: "Jan 26", price: 99.99 },
  { date: "Jan 27", price: 93.42 },
  { date: "Jan 28", price: 94.09 },
  { date: "Jan 29", price: 97.34 },
  { date: "Feb 01", price: 96.43 },
  { date: "Feb 02", price: 94.48 },
  { date: "Feb 03", price: 96.35 },
  { date: "Feb 04", price: 96.6 },
  { date: "Feb 05", price: 94.02 },
  { date: "Feb 08", price: 95.01 },
  { date: "Feb 09", price: 94.99 },
  { date: "Feb 10", price: 94.27 },
  { date: "Feb 11", price: 93.7 },
  { date: "Feb 12", price: 93.99 },
  { date: "Feb 16", price: 96.64 },
  { date: "Feb 17", price: 98.12 },
  { date: "Feb 18", price: 96.26 },
  { date: "Feb 19", price: 96.04 },
  { date: "Feb 22", price: 96.88 },
  { date: "Feb 23", price: 94.69 },
  { date: "Feb 24", price: 96.1 },
  { date: "Feb 25", price: 96.76 },
  { date: "Feb 26", price: 96.91 },
  { date: "Feb 29", price: 96.69 },
];

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const { x, y, stroke, payload } = this.props as any;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export class Dashboard extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      dates: [moment().startOf("month"), moment().endOf("day")],
      active: false,
      hasUnsavedFields: true,
      inStock: true,
      language: "pt",
      data: [],
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { dates } = this.state;

    dispatch(setTitle(Strings.sidebar.dashboard));
    delayedDispatch(
      setBreadcrumb(() => {
        const { dates } = this.state;
        return {
          actions: [
            {
              type: "datePicker",
              text: Strings.dashboard.period,
              dates: dates,
              onChange: this.handleSelect,
              className: "fixedPicker",
            },
          ],
        };
      })
    );

    let diff = dates[1].diff(dates[0], "days");
    if (diff > 30) {
      diff = 30;
    }

    this.setState({ data: dummyData.slice(0, diff) });
  }

  componentDidUpdate() {
    const { dispatch } = this.props;
    dispatch(updateCrumb());
  }

  handleSelect(dates: any, dateStrings: any) {
    if (!dates || dates.length !== 2) {
      return;
    }

    let diff = dates[1].diff(dates[0], "days") + 1;
    if (diff > 30) {
      diff = 30;
    }

    this.setState({ dates, data: dummyData.slice(0, diff) });
  }

  render() {
    const { data } = this.state;

    return (
      <React.Fragment>
        <Helmet>
          <title>{Strings.sidebar.dashboard}</title>
          <meta name="description" content="Description of Dashboard" />
        </Helmet>
        <ContentWrapper extraStyle={{ marginBottom: 20 }}>
          <div className="DashboardMainChartTitle">
			<Icon name="analysis" />
            <span>Gráfico 1</span>
          </div>
          <div className="DashboardMainChart">
            <ResponsiveContainer>
              <AreaChart
                data={data}
                margin={{ top: 5, right: 25, left: 0, bottom: 15 }}
              >
                <CartesianGrid vertical={false} strokeDasharray="1 3" />
                <XAxis
                  dataKey="date"
                  type="category"
                  tick={<CustomizedAxisTick />}
                />
                <YAxis domain={["auto", "auto"]} />
                <Tooltip
                  wrapperStyle={{
                    borderColor: "white",
                    boxShadow: "2px 2px 3px 0px rgb(204, 204, 204)",
                  }}
                  contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  labelStyle={{ fontWeight: "bold", color: "#333" }}
                />
                <Area
                  type="monotone"
                  dataKey="price"
                  stroke={variables.primaryColor}
                  fill={`${variables.primaryColor}80`}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </ContentWrapper>
        <Row gutter={[24, 24]}>
          <Col xs={12} sm={6} md={6}>
            <ContentWrapper extraClass="DashboardCard">
				<Card
					title="Card 1"
					hoverable={true}
					extra={<Icon name="responsive" className="DashboardCardIcon" />}
				>
					<p>Card content</p>
				</Card>
			</ContentWrapper>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <ContentWrapper extraClass="DashboardCard">
				<Card
					title="Card 2"
					hoverable={true}
					extra={<Icon name="responsive" className="DashboardCardIcon" />}
				>
					<p>Card content</p>
				</Card>
			</ContentWrapper>
          </Col>
          <Col xs={12} sm={6} md={6}>
            <ContentWrapper extraClass="DashboardCard">
				<Card
					title="Card 3"
					hoverable={true}
					extra={<Icon name="responsive" className="DashboardCardIcon" />}
				>
					<p>Card content</p>
				</Card>
			</ContentWrapper>
          </Col>
		  <Col xs={12} sm={6} md={6}>
            <ContentWrapper extraClass="DashboardCard">
				<Card
					title="Card 4"
					hoverable={true}
					extra={<Icon name="responsive" className="DashboardCardIcon" />}
				>
					<p>Card content</p>
				</Card>
			</ContentWrapper>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Dashboard);
