/*
 *
 * ProjectDetail
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Dropzone from "react-dropzone";
import Compressor from "compressorjs";
import { push } from "connected-react-router";

import moment from "moment";

import {
	Table,
	Icon,
	SelectableTabs,
	ContentWrapper,
	Editor,
	ImageGallery,
} from "components";
import {
	Select,
	Radio,
	Checkbox,
	Button,
	Row,
	Col,
	Input,
	InputNumber,
	Switch,
	DatePicker,
} from "antd";

import { SketchPicker } from "react-color";

import {
	setLoader,
	setTitle,
	delayedDispatch,
	setBreadcrumb,
	updateCrumb,
} from "store/actions";
import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";

import Strings from "utils/strings";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";

export class ProjectDetail extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			project: {},
			filesToDelete: [],
			selectedTab: "general",
			hasUnsavedFields: false,
			language: props.user?.language || "en",
			review: {
				image: null,
				name: '',
				position: {
					en: '',
					pt: ''
				},
				workQuality: 0,
				delivery: 0,
				response: 0,
				satisfaction: 0,
				totalCost: 0,
				referral: false,
				review: {
					en: '',
					pt: ''
				},
			}
		};
	}

	componentDidMount() {
		const { dispatch, match } = this.props;

		dispatch(setTitle(Strings.projects.project));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.projects,
							route: "/projects",
							icon: "tarefa",
						},
						{
							text:
								match?.params?.id === "new" &&
								(!this.state.project.name || this.state.project.name === "")
									? match.params.id
									: this.state.project.name,
						},
					],
					actions: [
						{
							type: "switch",
							text: Strings.projects.active,
							value: this.state.project.active,
							onClick: () => {
								this.toggleActive();
							},
						},
						{
							type: "switch",
							text: Strings.projects.wip,
							value: this.state.project.workInProgress,
							onClick: () => {
								this.toggleWIP();
							},
						},
						{
							type: "switch",
							text: Strings.projects.tbr,
							value: this.state.project.toBeReleased,
							onClick: () => {
								this.toggleTBR();
							},
						},
						{
							type: "language",
							value: this.state.language,
							onChange: (language: any) => this.setState({ language }),
						},
						{
							type: "button",
							onClick: () => this.saveProject(),
							text: Strings.generic.save,
							disabled: !this.state.hasUnsavedFields,
							isSave: true,
						},
					],
				};
			})
		);
		if (match?.params?.id !== "new") this.getProject();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
		dispatch(updateCrumb());
	}

	async getProject() {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriProjects(match.params.id),
			});
			if (response.ok) {
				this.setState({
					project: response.data.results || {},
				});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	sendReview = async (lang: any) => {
		const { dispatch, match } = this.props;

		dispatch(setLoader(true));

		try {
			const url = `${Endpoints.uriProjects(match.params.id)}/review/${lang}`;
			console.log('URL', url);
			const response = await API.patch({
				url,
			});
			if (response.ok) {
				toast.success('Review sent with sucess');
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	async saveProject() {
		const { dispatch, match } = this.props;
		const { project, filesToDelete } = this.state;

		const body = new FormData();
		// GENERAL
		body.append("active", project.active || false);
		body.append("workInProgress", project.workInProgress || false);
		body.append("toBeReleased", project.toBeReleased || false);

		if (typeof project.mainImage === "object" && project.mainImage !== null)
			body.append("mainImage", project.mainImage.file);
		else body.append("mainImage", project.mainImage);

		if (typeof project.secondImage === "object" && project.secondImage !== null)
			body.append("secondImage", project.secondImage.file);
		else body.append("secondImage", project.secondImage);

		if (typeof project.icon === "object" && project.icon !== null)
			body.append("icon", project.icon.file);
		else body.append("icon", project.icon);

		body.append("name", project.name || "");
		body.append(
			"briefDescription",
			JSON.stringify(project.briefDescription || {})
		);
		body.append("description", JSON.stringify(project.description || {}));
		body.append("_created", project._created || "");
		body.append("type", project.type || "mobile");
		body.append("linkWeb", project.linkWeb || "");
		body.append("linkGooglePlay", project.linkGooglePlay || "");
		body.append("linkAppStore", project.linkAppStore || "");
		body.append("link", project.link || "");

		const auxImages = [];
		if (project.images && project.images.length >= 1) {
			for (let i = 0; i < project.images.length; i++) {
				if (project.images[i].file) {
					body.append(`images_${i}`, project.images[i].file);
				} else {
					auxImages.push(project.images[i]);
				}
			}
		}
		body.append("images", JSON.stringify(auxImages || []));

		// WEB
		if (typeof project.categoryImage === "object" && project.categoryImage !== null)
			body.append("categoryImage", project.categoryImage.file);
		else body.append("categoryImage", project.categoryImage);

		if (typeof project.introImage === "object" && project.introImage !== null)
			body.append("introImage", project.introImage.file);
		else body.append("introImage", project.introImage);

		body.append("primaryColor", project.primaryColor || "");
		body.append("secondaryColor", project.secondaryColor || "");
		body.append("thirdColor", project.thirdColor || "");
		body.append("claim", JSON.stringify(project.claim || {}));
		body.append("duration", project.duration || 3);
		body.append("isSmartphone", project.isSmartphone || false);
		body.append("isTablet", project.isTablet || false);
		body.append("isWeb", project.isWeb || false);

		body.append("services", JSON.stringify(project.services || []));
		body.append("integrations", JSON.stringify(project.integrations || []));
		body.append("smallDescription", JSON.stringify(project.smallDescription || {}));
		if (project.review && typeof project.review.image === "object" && project.review.image !== null) {
			body.append("ownerImage", project.review.image.file);
			const review = {
				...project.review,
				image: null
			}
			body.append("review", JSON.stringify(review || {}));
		} else body.append("review", JSON.stringify(project.review || {}));
		
		// SECTIONS
		let auxSections = [];
		if (project.sections && project.sections.length >= 1) {
			for (let i = 0; i < project.sections.length; i++) {
				let auxSectionImages = [];
				if (project.sections[i].sectionImages?.length) {
					for (let j = 0; j < project.sections[i].sectionImages.length; j++) {
						if (project.sections[i].sectionImages[j].file) {
							body.append(
								`section_${i + 1}`,
								project.sections[i].sectionImages[j].file
							);
						} else {
							auxSectionImages.push(project.sections[i].sectionImages[j]);
						}
					}
				}
				auxSections.push({
					...project.sections[i],
					order: i + 1,
					sectionImages: auxSectionImages,
				});
			}
		}
		body.append("sections", JSON.stringify(auxSections || []));

		// FILES TO DELETE
		if (filesToDelete.length > 0)
			body.append("filesToDelete", JSON.stringify(filesToDelete || []));

		dispatch(setLoader(true));

		try {
			const response =
				match?.params?.id === "new"
					? await API.post({
							url: Endpoints.uriProjects(),
							data: body,
					  })
					: await API.put({
							url: Endpoints.uriProjects(match.params.id),
							data: body,
					  });
			if (response.ok) {
				if (match?.params?.id === "new")
					dispatch(push(`/projects/${response.data.results._id}`));
				else
					this.setState({
						project: response.data.results,
						filesToDelete: [],
						hasUnsavedFields: false,
					});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	array_move(arr: any, old_index: any, new_index: any) {
		if (new_index >= arr.length) {
			var k = new_index - arr.length + 1;
			while (k--) {
				arr.push(undefined);
			}
		}
		arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
		//return arr;
	}

	getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

	onDrop = (files: any, field: string) => {
		if (!field) return;

		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				mimeType: "image/png",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						let updateState = {
							project: {
								...this.state.project,
								[field]: { file: result, preview: res },
							},
							hasUnsavedFields: true,
						}

						if (field === 'ownerImage') {
							updateState.project.review.image = { file: result, preview: res };
						} else updateState.project[field] = { file: result, preview: res };
						this.setState((state: any) => (updateState));
					});
				},
			});
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	};

	toggleActive = () => {
		this.setState({
			project: { ...this.state.project, active: !this.state.project.active },
			hasUnsavedFields: true,
		});
	};

	toggleWIP() {
		this.setState({
			project: {
				...this.state.project,
				workInProgress: !this.state.project.workInProgress,
			},
			hasUnsavedFields: true,
		});
	}

	toggleTBR() {
		this.setState({
			project: {
				...this.state.project,
				toBeReleased: !this.state.project.toBeReleased,
			},
			hasUnsavedFields: true,
		});
	}

	renderColorPicker(
		color: any,
		displayColorPicker: any,
		handleClick: any,
		handleClose: any,
		handlePickerChange: any,
		handleInputChange: any
	) {
		return (
			<div style={{ display: "flex", flexDirection: "row" }}>
				<div
					style={{
						padding: "5px",
						marginRight: "6px",
						background: "#fff",
						borderRadius: "4px",
						border: "1px solid #d9d9d9",
						display: "inline-block",
						cursor: "pointer",
					}}
					onClick={handleClick}
				>
					<div
						style={{
							width: "30px",
							height: "100%",
							borderRadius: "4px",
							background: color,
						}}
					/>
				</div>
				{displayColorPicker ? (
					<div
						style={{
							position: "absolute",
							zIndex: 2,
						}}
					>
						<div
							style={{
								position: "fixed",
								top: "0px",
								right: "0px",
								bottom: "0px",
								left: "0px",
							}}
							onClick={handleClose}
						/>
						<SketchPicker color={color} onChange={handlePickerChange} />
					</div>
				) : null}
				<Input
					placeholder={Strings.projects.name}
					defaultValue={color || ""}
					value={color || ""}
					onChange={handleInputChange}
				/>
			</div>
		);
	}

	renderGeneral() {
		const { project, language } = this.state;
		return (
			<div>
				<ContentWrapper>
					<Row gutter={[24, 24]}>
					<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.categoryImage}
							</label>
							<div className="ImageContainer">
								<div className="Image">
									<img
										alt={Strings.fields.image}
										src={
											project.categoryImage !== null &&
											typeof project.categoryImage === "object"
												? project.categoryImage.preview
												: project.categoryImage &&
												  project.categoryImage !== "undefined"
												? project.categoryImage
												: placeholder
										}
									/>
									<div className="ImageOverlay">
										<Dropzone
											accept="image/jpg, image/jpeg, image/png"
											className="ImageOverlayOption"
											onDrop={(files) => this.onDrop(files, "categoryImage")}
										>
											<Icon name="pencil-outline" />
										</Dropzone>
										<div
											className={`ImageOverlayOption${
												Boolean(project.categoryImage) ? "" : " __disabled"
											}`}
											onClick={() => {
												const deleteArray = [...this.state.filesToDelete];
												if (
													project.categoryImage &&
													!project.categoryImage.file
												)
													deleteArray.push(project.categoryImage);

												this.setState({
													project: {
														...project,
														categoryImage: null,
													},
													filesToDelete: deleteArray,
													hasUnsavedFields: true,
												});
											}}
										>
											<Icon name="trash" />
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.icon}
							</label>
							<div className="ImageContainer">
								<div className="Image">
									<img
										alt={Strings.fields.image}
										src={
											project.icon !== null && typeof project.icon === "object"
												? project.icon.preview
												: project.icon && project.icon !== "undefined"
												? project.icon
												: placeholder
										}
									/>
									<div className="ImageOverlay">
										<Dropzone
											accept="image/jpg, image/jpeg, image/png"
											className="ImageOverlayOption"
											onDrop={(files) => this.onDrop(files, "icon")}
										>
											<Icon name="pencil-outline" />
										</Dropzone>
										<div
											className={`ImageOverlayOption${
												Boolean(project.icon) ? "" : " __disabled"
											}`}
											onClick={() => {
												const deleteArray = [...this.state.filesToDelete];
												if (project.icon && !project.icon.file)
													deleteArray.push(project.icon);

												this.setState({
													project: {
														...project,
														icon: null,
													},
													filesToDelete: deleteArray,
													hasUnsavedFields: true,
												});
											}}
										>
											<Icon name="trash" />
										</div>
									</div>
								</div>
							</div>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.name}
							</label>
							<Input
								placeholder={Strings.projects.name}
								defaultValue={project.name || ""}
								value={project.name || ""}
								onChange={(e) => {
									this.setState({
										project: { ...project, name: e.target.value },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.link}
							</label>
							<Input
								placeholder={"Link"}
								defaultValue={project.link ? project.link : ""}
								value={project.link ? project.link : ""}
								onChange={(e) => {
									this.setState({
										project: {
											...project,
											link: e.target.value
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">
								{Strings.projects.smallDescription}
							</label>
							<Input
								placeholder={"Small Description"}
								defaultValue={project.claim ? project.claim[language] : ""}
								value={project.claim ? project.claim[language] : ""}
								onChange={(e) => {
									this.setState({
										project: {
											...project,
											claim: {
												...project.claim,
												[language]: e.target.value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">
								{Strings.projects.description}
							</label>
							<Editor
								extraData={language}
								btnBlacklist={["source"]}
								value={
									project.smallDescription
										? project.smallDescription[language]
										: ""
								}
								onChange={(data: any) => {
									this.setState({
										project: {
											...project,
											smallDescription: {
												...project.smallDescription,
												[language]: data,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.duration}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={1}
								max={100}
								defaultValue={1}
								value={project.duration}
								onChange={(value) => {
									this.setState({
										project: { ...project, duration: value },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.typeProject}
							</label>
							<Radio.Group
								style={{ width: "100%" }}
								value={project.type}
								defaultValue={"mobile"}
								onChange={(e) => {
									this.setState({
										project: {
											...project,
											type: e.target.value,
										},
										hasUnsavedFields: true,
									});
								}}
							>
								<Radio.Button
									style={{ width: "33.3%", textAlign: "center" }}
									value="mobile"
								>
									Mobile
								</Radio.Button>
								<Radio.Button
									style={{ width: "33.3%", textAlign: "center" }}
									value="web"
								>
									Web
								</Radio.Button>
								<Radio.Button
									style={{ width: "33.3%", textAlign: "center" }}
									value="both"
								>
									Both
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.linkAppStore}
							</label>
							<Input
								placeholder={Strings.placeholders.link}
								defaultValue={project.linkAppStore || ""}
								value={project.linkAppStore || ""}
								onChange={(e) => {
									this.setState({
										project: { ...project, linkAppStore: e.target.value },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.linkGooglePlay}
							</label>
							<Input
								placeholder={Strings.placeholders.link}
								defaultValue={project.linkGooglePlay || ""}
								value={project.linkGooglePlay || ""}
								onChange={(e) => {
									this.setState({
										project: { ...project, linkGooglePlay: e.target.value },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.linkWeb}
							</label>
							<Input
								placeholder={Strings.placeholders.link}
								defaultValue={project.linkWeb || ""}
								value={project.linkWeb || ""}
								onChange={(e) => {
									this.setState({
										project: { ...project, linkWeb: e.target.value },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.primaryColor}
							</label>
							{this.renderColorPicker(
								project.primaryColor,
								this.state.displayColorPicker1,
								() => {
									this.setState({
										displayColorPicker1: !this.state.displayColorPicker1,
									});
								},
								() => {
									this.setState({ displayColorPicker1: false });
								},
								(color: any) => {
									this.setState({
										project: { ...project, primaryColor: color.hex },
										hasUnsavedFields: true,
									});
								},
								(e: any) => {
									this.setState({
										project: { ...project, primaryColor: e.target.value },
										hasUnsavedFields: true,
									});
								}
							)}
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.secondaryColor}
							</label>
							{this.renderColorPicker(
								project.secondaryColor,
								this.state.displayColorPicker2,
								() => {
									this.setState({
										displayColorPicker2: !this.state.displayColorPicker2,
									});
								},
								() => {
									this.setState({ displayColorPicker2: false });
								},
								(color: any) => {
									this.setState({
										project: { ...project, secondaryColor: color.hex },
										hasUnsavedFields: true,
									});
								},
								(e: any) => {
									this.setState({
										project: { ...project, secondaryColor: e.target.value },
										hasUnsavedFields: true,
									});
								}
							)}
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.thirdColor}
							</label>
							{this.renderColorPicker(
								project.thirdColor,
								this.state.displayColorPicker3,
								() => {
									this.setState({
										displayColorPicker3: !this.state.displayColorPicker3,
									});
								},
								() => {
									this.setState({ displayColorPicker3: false });
								},
								(color: any) => {
									this.setState({
										project: { ...project, thirdColor: color.hex },
										hasUnsavedFields: true,
									});
								},
								(e: any) => {
									this.setState({
										project: { ...project, thirdColor: e.target.value },
										hasUnsavedFields: true,
									});
								}
							)}
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.typeProject}
							</label>
							<Checkbox.Group
								style={{ width: "100%" }}
								options={["Smartphone", "Tablet", "Web"]}
								defaultValue={[""]}
								value={[
									...(project.isSmartphone ? ["Smartphone"] : []),
									...(project.isTablet ? ["Tablet"] : []),
									...(project.isWeb ? ["Web"] : []),
								]}
								onChange={(checkedValues) => {
									const isSmartphone = checkedValues.includes("Smartphone");
									const isTablet = checkedValues.includes("Tablet");
									const isWeb = checkedValues.includes("Web");
									this.setState({
										project: {
											...project,
											isSmartphone: isSmartphone,
											isTablet: isTablet,
											isWeb: isWeb,
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={12}>
							<label className="SingleLabel __required">
								{Strings.projects.services}
							</label>
							<Checkbox.Group
								style={{ width: "100%" }}
								options={[
									"iOS",
									"Android",
									"Web",
									"Backend",
									"Backoffice",
									"Design",
									"Logo Design",
									"Marketing",
								]}
								defaultValue={[""]}
								value={project.services}
								onChange={(checkedValues) => {
									this.setState({
										project: { ...project, services: checkedValues },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">
								{Strings.projects.integrations}
							</label>
							<Checkbox.Group
								style={{ width: "100%" }}
								options={[
									"AWS",
									"AWS SES",
									"Braintree",
									"Fabric",
									"Facebook",
									"Firebase",
									"Google Cloud",
									"Google Maps",
									"Google Plus",
									"Invoice Express",
									"MBway",
									"Sendgrid",
									"Stripe",
									"Twillio",
									"EasyPay",
									"Apple Login",
								]}
								defaultValue={[""]}
								value={project.integrations}
								onChange={(checkedValues) => {
									this.setState({
										project: { ...project, integrations: checkedValues },
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
				</ContentWrapper>
			</div>
		);
	}

	renderSection(row: any) {
		const { project, language } = this.state;
		return (
			<ContentWrapper>
				<ContentWrapper>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">
								{Strings.projects.sectionName}
							</label>
							<Input
								placeholder={"Section Name"}
								defaultValue={
									project.sections[row.id].title
										? project.sections[row.id].title[language]
										: ""
								}
								value={
									project.sections[row.id].title
										? project.sections[row.id].title[language]
										: ""
								}
								onChange={(e) => {
									this.setState(
										{
											project: {
												...project,
												sections: project.sections.map(
													(element: any, index: any) => {
														if (index === row.index)
															return {
																...element,
																title: {
																	...element.title,
																	[language]: e.target.value,
																},
															};
														return element;
													}
												),
											},
											hasUnsavedFields: true,
										},
										() => {
											row.toggleRowExpanded(true);
										}
									);
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={12} lg={7}>
							<label className="SingleLabel __required">
								{Strings.projects.type}
							</label>
							<Select
								defaultValue="Smartphone"
								style={{ width: "100%" }}
								value={
									project.sections[row.id].type
										? project.sections[row.id].type
										: ""
								}
								onChange={(value) => {
									this.setState(
										{
											project: {
												...project,
												sections: project.sections.map(
													(element: any, index: any) => {
														if (index === row.index)
															return {
																...element,
																type: value,
															};
														return element;
													}
												),
											},
											hasUnsavedFields: true,
										},
										() => {
											row.toggleRowExpanded(true);
										}
									);
								}}
							>
								<Select.Option value="Smartphone">Smartphone</Select.Option>
								<Select.Option value="Tablet">Tablet</Select.Option>
								<Select.Option value="Web">Web</Select.Option>
								<Select.Option value="Backoffice">Backoffice</Select.Option>
								<Select.Option value="Backend">Backend</Select.Option>
								<Select.Option value="Design">Design</Select.Option>
								<Select.Option value="Marketing">Marketing</Select.Option>
							</Select>
						</Col>
						<Col xs={12} lg={7}>
							<label className="SingleLabel __required">
								{Strings.projects.order}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={1}
								max={project.sections.length}
								defaultValue={row.id + 1}
								value={row.id + 1}
								onChange={(value: any) => {
									if (project.sections.length > 1) {
										this.array_move(project.sections, row.id, value - 1);
									}
									this.setState({
										project: {
											...project,
											sections: project.sections.map(
												(element: any, index: any) => {
													if (index === row.index)
														return {
															...element,
															order: value - 1,
														};

													return element;
												}
											),
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={10}>
							<label className="SingleLabel __required">
								{Strings.projects.technologies}
							</label>
							<Checkbox.Group
								style={{ width: "100%" }}
								options={[
									"ReactJS",
									"AngularJS",
									"PHP",
									"HTML5",
									"CSS",
									"React Native",
									"Swift",
									"Java",
									"Flutter",
									"Expo",
									"Vue",
								]}
								defaultValue={[""]}
								value={
									project.sections[row.id].technologies
										? project.sections[row.id].technologies
										: [""]
								}
								onChange={(checkedValues) => {
									this.setState(
										{
											project: {
												...project,
												sections: project.sections.map(
													(element: any, index: any) => {
														if (index === row.index)
															return {
																...element,
																technologies: checkedValues,
															};
														return element;
													}
												),
											},
											hasUnsavedFields: true,
										},
										() => {
											row.toggleRowExpanded(true);
										}
									);
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">
								{Strings.projects.sectionDescription}
							</label>
							<Editor
								extraData={language}
								btnBlacklist={["source"]}
								value={
									project.sections[row.id].description
										? project.sections[row.id].description[language]
										: ""
								}
								onChange={(data: any) => {
									this.setState(
										{
											project: {
												...project,
												sections: project.sections.map(
													(element: any, index: any) => {
														if (index === row.index)
															return {
																...element,
																description: {
																	...element.description,
																	[language]: data,
																},
															};
														return element;
													}
												),
											},
											hasUnsavedFields: true,
										},
										() => {
											row.toggleRowExpanded(true);
										}
									);
								}}
							/>
						</Col>
					</Row>
				</ContentWrapper>
				<ContentWrapper>
					<ImageGallery
						images={project.sections[row.id].sectionImages || []}
						onDelete={(index: any) => {
							const imagesClone = [
								...project.sections[row.id].sectionImages,
							].filter((element: any, ix: any) => {
								if (ix !== index) return element;
							});
							const deleteArray = [...this.state.filesToDelete];
							if (
								project.sections[row.id].sectionImages[index] &&
								!project.sections[row.id].sectionImages[index].file
							)
								deleteArray.push(project.sections[row.id].sectionImages[index]);

							this.setState(
								{
									project: {
										...this.state.project,
										sections: project.sections.map(
											(element: any, index: any) => {
												if (index === row.index)
													return {
														...element,
														sectionImages: imagesClone,
													};
												return element;
											}
										),
									},
									filesToDelete: deleteArray,
									hasUnsavedFields: true,
								},
								() => {
									row.toggleRowExpanded(true);
								}
							);
						}}
						onDrop={async (files: any) => {
							const imagesClone = [...project.sections[row.id].sectionImages];
							for (let i = 0; i < files.length; i++) {
								try {
									const file = files[i];

									new Compressor(file, {
										mimeType: "image/png",
										success: (result: any) => {
											this.getBase64(result).then((res) => {
												imagesClone.push({ file: result, preview: res });
												this.setState(
													{
														project: {
															...this.state.project,
															sections: project.sections.map(
																(element: any, index: any) => {
																	if (index === row.index)
																		return {
																			...element,
																			sectionImages: imagesClone,
																		};
																	return element;
																}
															),
														},
														hasUnsavedFields: true,
													},
													() => {
														row.toggleRowExpanded(true);
													}
												);
											});
										},
									});
								} catch (err) {
									toast.warning(Strings.errors.notSupportedFile);
								}
							}
						}}
						onSetList={(setList: any) => {
							for (let i = 0; i < setList.length; i++) {
								if (typeof setList[i] === "object" && setList[i] !== null)
									return;
							}
							this.setState(
								{
									project: {
										...this.state.project,
										sections: project.sections.map(
											(element: any, index: any) => {
												if (index === row.index)
													return {
														...element,
														sectionImages: setList,
													};
												return element;
											}
										),
									},
									hasUnsavedFields: true,
								},
								() => {
									row.toggleRowExpanded(true);
								}
							);
						}}
					/>
				</ContentWrapper>
			</ContentWrapper>
		);
	}

	renderSections() {
		const { project, language } = this.state;
		return (
			<Table
				title={{
					icon: "tarefa",
					title: Strings.projects.sections || "",
				}}
				data={project.sections}
				columns={[
					{
						Header: Strings.projects.title,
						id: "title",
						accessor: (row: any) =>
							row.title ? row.title[language] : row.title,
					},
					{
						Header: Strings.projects.type,
						id: "type",
						accessor: (row: any) => row.type,
					},
				]}
				expanded
				renderExpanded={(row: any) => this.renderSection(row)}
				add={{
					label: String(
						Strings.formatString(Strings.generic.addNewM, "section")
					),
					onClick: () => {
						if (project.sections?.length > 0) {
							const sectionsClone = [...project.sections];
							const order = project.sections?.length + 1;
							sectionsClone.push({
								order: order,
								sectionImages: [],
								technologies: [],
								title: { en: "Section " + order, pt: "Secção " + order },
								type: "Web",
							});
							this.setState({
								project: {
									...project,
									sections: sectionsClone,
								},
								hasUnsavedFields: true,
							});
						} else {
							this.setState({
								project: {
									...project,
									sections: [
										{
											order: 1,
											sectionImages: [],
											technologies: [],
											title: { en: "Section 1", pt: "Secção 1" },
											type: "Web",
										},
									],
								},
								hasUnsavedFields: true,
							});
						}
					},
				}}
				actions={{
					remove: (original, value) => ({
						onClick: () => {
							if (project.sections?.length > 0) {
								const sectionsClone = [...project.sections].filter(
									(element: any, index: any) => {
										if (index !== value.index) return element;
									}
								);
								this.setState({
									project: {
										...project,
										sections: sectionsClone,
									},
									hasUnsavedFields: true,
								});
							}
						},
					}),
				}}
			/>
		);
	}

	renderReview() {
		const { project, language } = this.state;
		return (
			<div>
				<ContentWrapper>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={8}>
							<label className="SingleLabel __required">
								{Strings.projects.ownerImage}
							</label>
							<div className="ImageContainer">
								<div className="Image">
									<img
										alt={Strings.fields.image}
										src={
											project.review.image !== null &&
											typeof project.review.image === "object"
												? project.review.image.preview
												: project.review.image &&
												  project.review.image !== "undefined"
												? project.review.image
												: placeholder
										}
									/>
									<div className="ImageOverlay">
										<Dropzone
											accept="image/jpg, image/jpeg, image/png"
											className="ImageOverlayOption"
											onDrop={(files) => this.onDrop(files, "ownerImage")}
										>
											<Icon name="pencil-outline" />
										</Dropzone>
										<div
											className={`ImageOverlayOption${
												Boolean(project.review.image) ? "" : " __disabled"
											}`}
											onClick={() => {
												const deleteArray = [...this.state.filesToDelete];
												if (project.review.image && !project.review.image.file)
													deleteArray.push(project.review.image);

												this.setState({
													project: {
														...project,
														review: {
															...project.review,
															image: null,
														},
													},
													filesToDelete: deleteArray,
													hasUnsavedFields: true,
												});
											}}
										>
											<Icon name="trash" />
										</div>
									</div>
								</div>
							</div>
						</Col>
						<Col xs={24} lg={16}>
							<Row>
								<label className="SingleLabel __required">
									{Strings.projects.ownerName}
								</label>
								<Input
									placeholder={Strings.projects.ownerName}
									defaultValue={
										project.review.name
											? project.review.name
											: ''
									}
									value={
										project.review.name
											? project.review.name
											: ''
									}
									onChange={(e) => {
										this.setState({
											project: {
												...project,
												review: {
													...project.review,
													name: e.target.value,
												},
											},
											hasUnsavedFields: true,
										});
									}}
								/>
							</Row>
							<Row>
								<label className="SingleLabel __required">
									{Strings.projects.ownerPosition}
								</label>
								<Input
									placeholder={Strings.projects.ownerPosition}
									defaultValue={
										project.review.position
											? project.review.position[language]
											: ''
									}
									value={
										project.review.position
											? project.review.position[language]
											: ''
									}
									onChange={(e) => {
										this.setState({
											project: {
												...project,
												review: {
													...project.review,
													position: {
														...project.review.position,
														[language]: e.target.value,
													}
												},
											},
											hasUnsavedFields: true,
										});
									}}
								/>
							</Row>
							<Row>
								<Col xs={24} lg={18}>
									<label className="SingleLabel __required">
										{Strings.projects.ownerEmail}
									</label>
									<Input
										placeholder={Strings.projects.ownerEmail}
										defaultValue={
											project.review.email
												? project.review.email
												: ''
										}
										value={
											project.review.email
												? project.review.email
												: ''
										}
										onChange={(e) => {
											this.setState({
												project: {
													...project,
													review: {
														...project.review,
														email: e.target.value,
													},
												},
												hasUnsavedFields: true,
											});
										}}
									/>
								</Col>
								<Col xs={24} lg={3}>
									<div className="GenericButton">
										<Button
											className={"GenericButton"}
											onClick={() => this.sendReview('pt')}
											disabled={!project.review.email}
										>
											<span>{!project.review.emailSent ? 'Send PT' : 'Resend PT'}</span>
										</Button>
									</div>
								</Col>
								<Col xs={24} lg={3}>
									<div className="GenericButton">
										<Button
											className={"GenericButton"}
											onClick={() => this.sendReview('en')}
											disabled={!project.review.email}
										>
											<span>{!project.review.emailSent ? 'Send EN' : 'Resend EN'}</span>
										</Button>
									</div>
								</Col>
							</Row>
							<Row>
								<div className={`GenericSwitch${project?.review?.published ? " __active" : ""}`}>
									<span>Published</span>
									<Switch
										className={`Switch ${project?.review?.published ? "__active" : ""}`}
										checked={project?.review?.published || false}
										size="small"
										onChange={(value: boolean) =>
											this.setState({
												project: { ...project, 
													review: {
														...project.review,
														published: value,
													},
												},
												hasUnsavedFields: true,
											})
										}
									/>
								</div>
							</Row>
						</Col>
					</Row>
				</ContentWrapper>
				<ContentWrapper>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={8}>
							<label className="SingleLabel">
								{Strings.projects.workQuality}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={5}
								defaultValue={0}
								value={project.review.workQuality}
								onChange={(value) => {
									this.setState({
										project: { ...project, 
											review: {
												...project.review,
												workQuality: value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel">
								{Strings.projects.delivery}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={5}
								defaultValue={0}
								value={project.review.delivery}
								onChange={(value) => {
									this.setState({
										project: { ...project, 
											review: {
												...project.review,
												delivery: value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel">
								{Strings.projects.response}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={5}
								defaultValue={0}
								value={project.review.response}
								onChange={(value) => {
									this.setState({
										project: { ...project, 
											review: {
												...project.review,
												response: value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
					</Row>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={8}>
							<label className="SingleLabel">
								{Strings.projects.satisfaction}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={5}
								defaultValue={0}
								value={project.review.satisfaction}
								onChange={(value) => {
									this.setState({
										project: { ...project, 
											review: {
												...project.review,
												satisfaction: value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<label className="SingleLabel">
								{Strings.projects.totalCost}
							</label>
							<InputNumber
								style={{ width: "100%" }}
								min={0}
								max={1000000}
								defaultValue={0}
								value={project.review.totalCost}
								onChange={(value) => {
									this.setState({
										project: { ...project, 
											review: {
												...project.review,
												totalCost: value,
											},
										},
										hasUnsavedFields: true,
									});
								}}
							/>
						</Col>
						<Col xs={24} lg={8}>
							<div className={`GenericSwitch${project?.review?.referral ? " __active" : ""}`}>
                                <span>Referral</span>
                                <Switch
                                    className={`Switch ${project?.review?.referral ? "__active" : ""}`}
                                    checked={project?.review?.referral || false}
                                    size="small"
                                    onChange={(value: boolean) =>
                                        this.setState({
											project: { ...project, 
												review: {
													...project.review,
													referral: value,
												},
											},
                                            hasUnsavedFields: true,
                                        })
                                    }
                                />
                            </div>
						</Col>
					</Row>
				</ContentWrapper>
				<ContentWrapper>
					<Row gutter={[24, 24]}>
						<Col xs={24} lg={24}>
							<label className="SingleLabel __required">{Strings.projects.review}</label>
							<Input.TextArea
								disabled={false}
								placeholder={Strings.projects.review}
								value={project?.review?.review?.[language]}
								style={{ minHeight: '120px' }}
								onChange={(e: any) =>
									this.setState({
										project: {
											...project,
											review: {
												...project.review,
												review: {
													...project.review.review,
													[language]: e.target.value,
												}
											},
										},
										hasUnsavedFields: true,
									})
								}
							/>
						</Col>
					</Row>
				</ContentWrapper>
				
			</div>
		);
	}

	render() {
		const { selectedTab } = this.state;
		const tabs = [
			{
				key: "general",
				title: Strings.projects.general,
				onClick: () => this.setState({ selectedTab: "general" }),
				renderScreen: () => this.renderGeneral(),
			},
			{
				key: "sections",
				title: Strings.projects.sections,
				onClick: () => this.setState({ selectedTab: "sections" }),
				renderScreen: () => this.renderSections(),
			},
			{
				key: "review",
				title: Strings.projects.review,
				onClick: () => this.setState({ selectedTab: "review" }),
				renderScreen: () => this.renderReview(),
			},
		];

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.projects.project}</title>
					<meta name="description" content="Description of ProjectDetail" />
				</Helmet>
				<SelectableTabs selectedTab={selectedTab} tabs={tabs}></SelectableTabs>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(ProjectDetail);
