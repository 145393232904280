/*
 *
 * Simulations
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";

import { Table } from "components";
import {
	setLoader,
	setTitle,
	delayedDispatch,
	setBreadcrumb,
	updateCrumb,
} from "store/actions";

import { API, Endpoints } from "utils/api";
import { toast } from "utils/utils";

import Strings from "utils/strings";
import "./styles.scss";

export class Simulations extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			simulations: [],
			language: props.user?.language || null,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.simulations));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.simulations,
							icon: "connection1",
						},
					],
				};
			})
		);

		this.getSimulations();
	}

	componentDidUpdate() {
        const { dispatch } = this.props;
        dispatch(updateCrumb());
    }

	async getSimulations() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriSimulations(),
			});

			if (response.ok) {
				this.setState({
					simulations: response.data.results || [],
				});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	onDelete = async (id: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.delete({ url: Endpoints.uriSimulations(id) })
			.then((response) => {
				if (response.ok && response.data.results) {
					this.setState({ simulations: response.data.results });
				} else toast.error(response.data.message);
			})
			.catch((err) => {
				toast.error(err);
			});

		dispatch(setLoader(false));
	};

	render() {
		const { simulations = [], language = "en" } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Simulations</title>
					<meta name="description" content="Description of Simulations" />
				</Helmet>
				<Table
					title={{
						icon: "connection1",
						title: Strings.sidebar.simulations,
					}}
					data={simulations}
					columns={[
						{
							Header: Strings.fields.date,
							id: "date",
							accessor: (row: any) => moment(row._created).format("DD-MM-YYYY"),
						},
						{
							Header: Strings.fields.email,
							id: "email",
							accessor: (row: any) => row.email,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.phone,
							id: "phone",
							accessor: (row: any) => row.phone,
						},
						{
							Header: Strings.fields.score,
							id: "score",
							accessor: (row: any) => row.score,
						},
					]}
					filterable
					sortable
					expanded
					renderExpanded={(row: any) => {
						return (
							<Table
								title={{
									icon: "parts",
									title: Strings.simulations.answers,
								}}
								data={row?.original?.answers || []}
								columns={[
									{
										Header: Strings.simulations.question,
										id: "question",
										accessor: (row: any) => row.question[language],
										minWidth: 130,
									},
									{
										Header: Strings.simulations.answer,
										id: "answer",
										align: "right",
										accessor: (row: any) => row.answer[language],
									},
									{
										Header: Strings.simulations.value,
										id: "value",
										align: "right",
										accessor: (row: any) => row.answer["value"],
									},
								]}
								paginated={false}
							/>
						);
					}}
					actions={{
						remove: (original, value) => ({
							onClick: () => this.onDelete(original._id),
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
});

export default connect(mapStateToProps)(Simulations);
