// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".isUserMenu{height:30px !important}.AdjustUserMenu:not(.active){margin-bottom:0 !important}.SidebarUserLogo img{position:relative;z-index:2;width:30px;min-width:30px;height:30px;min-height:30px;border-radius:50%}.SidebarMenuOptions{display:flex;justify-content:space-between;margin:0 10px;transition:.2s;user-select:none}.SidebarMenuOptions.__forceWidth{width:200px}.SidebarMenuOptions.__mobile{flex-direction:column}.SidebarMenuOptions .SidebarMenuOption{display:flex;align-items:center;padding:10px;font-size:20px;border-radius:4px;cursor:pointer;transition:.2s}.SidebarMenuOptions .SidebarMenuOption span{line-height:12px}.SidebarMenuOptions .SidebarMenuOption:hover{background-color:#00000020}.SidebarNavName.__normal{text-transform:none}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
