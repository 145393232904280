/*
*
* Users
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setLoader, setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
import { Table } from 'components';
import { Helmet } from 'react-helmet';
import Strings from 'utils/strings';
import './styles.scss';
import { API, Endpoints } from 'utils/api';

export class Users extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			users: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.users));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
                          text: Strings.sidebar.users,
                          icon: "user2",
                        },
					],
                }
            })
		);

		this.getUsers();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	async getUsers() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		const response = await API.post({
			url: Endpoints.uriUsers(),
			date: {},
		});

		if (response.ok) {
			this.setState({ users: response.data.results.collection || [] });
		}

		dispatch(setLoader(false));
	}

	render() {
		const { users = [] } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.users}</title>
					<meta name="description" content="Description of Users" />
				</Helmet>
				<Table
					title={{
						icon: "user2",
						title: Strings.sidebar.users
					}}
					data={users}
					columns={[
						{
							Header: Strings.fields.name,
							id: 'name',
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.email,
							id: 'email',
							accessor: (row: any) => row.email,
						},
					]}
					filterable
					actions={{
						onEdit: (obj: any) => {
							// do something
						},
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Users);