import { toast as Toast } from 'react-toastify';
import Strings from 'utils/strings';

export const trimObjects = (obj: any) => {
	for (const prop in obj) {
		obj[prop] = obj[prop].trim();
	}
	return obj;
};

export const emailIsValid = (email: string, error?: any) => {
	const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const isValid = email.match(emailRegex);

	if (!isValid && error) {
		toast.warn(error);
	}

	return isValid;
}

export const capitalize = (string: string) => {
	if (!string) return '';
	return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}

export const translate = (text: any) => {
	if (!text) return '';

	if (typeof text === 'string') return text;

	const lang = Strings.getLanguage();
	const defaultLanguage = Strings.getLanguage();

	if (text[defaultLanguage] || text[lang]) {
		return text[defaultLanguage] || text[lang];
	}

	return Object.values(text).filter(val => !!val)[0] || '';
};

// New toast popup snippet
// Usage: popup.warn(message), popup.warn(message), popup.success(message), popup.error(message)
// Prevents multiple toast with the same message

const TOAST_CLOSETIME = 5000;

class Popup {
	warning(message: String) {
		if (!message) throw new Error(Strings.errors.invalidArgs);

		if (Toast.isActive(`warning_${message}`)) {
			Toast.update(`warning_${message}`, { autoClose: TOAST_CLOSETIME })
		} else {
			Toast.warning(message, { toastId: `warning_${message}` });
		}
	}

	warn(message: String) {
		this.warning(message);
	}

	success(message: String) {
		if (!message) throw new Error(Strings.errors.invalidArgs);

		if (Toast.isActive(`success_${message}`)) {
			Toast.update(`success_${message}`, { autoClose: TOAST_CLOSETIME })
		} else {
			Toast.success(message, { toastId: `success_${message}` });
		}
	}

	error(message: String) {
		if (!message) throw new Error(Strings.errors.invalidArgs);

		if (Toast.isActive(`error_${message}`)) {
			Toast.update(`error_${message}`, { autoClose: TOAST_CLOSETIME })
		} else {
			Toast.error(message, { toastId: `error_${message}` });
		}
	}
}

export const LANGUAGES = [
	{
		value: 'pt',
		label: 'PT',
	},
	{
		value: 'en',
		label: 'EN',
	},
];

export const toast = new Popup();
// End of toast snippet