/*
 *
 * Projects
 *
 */

import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";

import { Table, Icon } from "components";
import {
	setLoader,
	setTitle,
	delayedDispatch,
	setBreadcrumb,
	updateCrumb,
} from "store/actions";

import { API, Endpoints } from "utils/api";
import { push } from "connected-react-router";
import { toast } from "utils/utils";

import Strings from "utils/strings";
import "./styles.scss";

export class Projects extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			pageSize: 100,
			projects: [],
			isActive: false,
			language: props.user?.language || null,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.projects));

		delayedDispatch(
			setBreadcrumb(() => {
				return {
					locations: [
						{
							text: Strings.sidebar.projects,
							icon: "tarefa",
						},
					],
					actions: [
						{
							type: "switch",
							text: Strings.projects.hideOS,
							value: this.state.isActive,
							onClick: () =>{
								this.setState({
									isActive: !this.state.isActive,
								});
							}
						},
					],
				};
			})
		);

		this.getProjects();
	}

	componentDidUpdate() {
        const { dispatch } = this.props;
        dispatch(updateCrumb());
    }

	onDrag = async () => {
		const { projects, hoverIndex } = this.state;
		const { dispatch } = this.props;
	
		const project = projects[hoverIndex];
		const projectId = project?._id;
	
		if (!projectId || project.pos === hoverIndex) return;
	
		dispatch(setLoader(true));
	
		await API.patch({
			url: Endpoints.uriProjects(`position/${projectId}`),
			data: { pos: hoverIndex },
		})
			.then((response) => {
				if (response.ok && response.data.results.projects) {
				this.setState({ projects: response.data.results.projects });
				} else
				toast.error(response.data?.message || Strings.serverErrors.title);
			})
			.catch((err) => {
				toast.error(err);
			});
	
		dispatch(setLoader(false));
	};

	async getProjects() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriProjects(),
			});
			if (response.ok) {
				this.setState({
					projects: response.data.results || [],
				});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	}

	onAdd() {
		const { dispatch } = this.props;
		dispatch(push("/projects/new"));
	}

	onEdit = (id: any) => {
		const { dispatch } = this.props;
		dispatch(push(`/projects/${id}`));
	}

	onToggle = async (item: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.patch({
				url: Endpoints.uriProjects(item._id),
				data: {active : !item.active}
			});
			if (response.ok) {
				this.setState({
					projects: response.data.results || [],
				});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	};

	onDelete = async (id: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.delete({
				url: Endpoints.uriProjects(id),
			});
			if (response.ok) {
				this.setState({
					projects: response.data.results || [],
				});
			}
		} catch (err) {
			toast.error(err);
		}

		dispatch(setLoader(false));
	};

	render() {
		const { projects = [], language = "en" } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.projects}</title>
					<meta name="description" content="Description of Projects" />
				</Helmet>
				<Table
					title={{
						icon: "tarefa",
						title: Strings.sidebar.projects,
					}}
					data={projects}
					columns={[
						{
							Header: Strings.fields.photo,
							id: 'photo',
							accessor: (row: any) => row.icon,
							type: 'image',
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: "name",
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.date,
							id: "date",
							accessor: (row: any) => moment(row._created).format("DD-MM-YYYY"),
						},
					]}
					filterable
					draggable
					onDrag={async (list: any, dragIndex: any, hoverIndex: any) => {
					  this.setState({ projects: list, dragIndex, hoverIndex })
					}}
					onDragEnd={this.onDrag}
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNewM,
								Strings.projects.project.toLowerCase()
							)
						),
						onClick: () => this.onAdd(),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => this.onEdit(original._id),
						}),
						remove: (original, value) => ({
							onClick: () => this.onDelete(original._id),
						}),
						toggle: (original: any, value: any) => ({
							value: original.active,
							onChange: () => {
								this.onToggle(original);
							},
						}),
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(Projects);
