/*
*
* JobOportunities
*
*/

import React from 'react';
import { connect } from 'react-redux';
import { setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
import { Helmet } from 'react-helmet';
import { Table } from 'components';
import { API , Endpoints } from 'utils/api';
import { push } from 'connected-react-router';
import Strings from 'utils/strings';
import './styles.scss';

export class JobOportunities extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			jobOportunities: [],
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.jobOportunities));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
						{
							text: Strings.sidebar.jobOportunities,
							icon: "text-files",
						},
					],
                }
            })
		);

		this.getJobOportunities();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}
	onAdd() {
		const { dispatch } = this.props;
		dispatch(push("/job-oportunities/new"));
	}

	getJobOportunities = async () => {
		const response = await API.get({
			url: Endpoints.uriJobOportunities(),
		});
		
		if (response.ok) {
			const { jobOportunities } = response.data.results;
			this.setState({ jobOportunities });
		}
	}

	patchJobOportunity = async ( original : any) =>{
		let body = {isActive: !original.isActive};
		const response= await API.patch({
			url: Endpoints.uriJobOportunities(original._id),
			data: body,
		});

		if(response.ok){
			this.setState({ jobOportunities: response.data.results.jobOportunities });
		}
	}

	render() {
		const { jobOportunities = [] } = this.state;
		const { dispatch } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.jobOportunities}</title>
					<meta name="description" content="Description of Job Oportunities" />
				</Helmet>
				<Table
					title={{
						icon: "working-briefcase",
						title: Strings.sidebar.jobOportunities
					}}
					data={jobOportunities}
					columns={[
						{
							Header: Strings.jobOportunities.single,
							id: 'name',
							accessor: (row: any) => row.title,
						},
					]}
					filterable
					add={{
						label: String(
							Strings.formatString(
								Strings.generic.addNewM,
								Strings.jobOportunities.single.toLowerCase()
							)
						),
						onClick: () => this.onAdd(),
					}}
					actions={{
						edit: (original, value) => ({
							onClick: () => dispatch(push(`/job-oportunities/${original._id}`)),
						}),
						toggle: (original, value) =>({
							value: original.isActive,
							onChange: () => this.patchJobOportunity(original),
						})
					}}
				/>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps)(JobOportunities);