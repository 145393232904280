// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
