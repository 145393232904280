// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".tox-notification,.tox-statusbar__branding{display:none !important}.ace_editor{width:100% !important;height:600px !important}.ace_editor,.ace_editor *{font-family:\"Monaco\",\"Menlo\",\"Ubuntu Mono\",\"Droid Sans Mono\",\"Consolas\",monospace !important}.email_preview_block{width:100%;height:600px;display:flex;justify-content:center}.email_preview_block .email_preview{border:1px solid gray;padding:1px;overflow-y:auto;width:100%;height:100%}.email_preview_block .email_preview.preview_mobile{width:380px}.email_preview_block .email_preview iframe{height:100%;width:100%}.email_preview_options{display:flex;justify-content:space-between;align-items:center}.email_preview_options .email_preview_devices{display:flex;justify-content:center;align-items:center;padding:5px}.email_preview_options .email_preview_devices em{font-size:24px;cursor:pointer}.email_preview_options .email_preview_devices.active{color:#a3681b}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
