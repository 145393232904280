/**
 *
 * ImageGallery
 *
 */

import React from "react";
import { ContentWrapper } from "components";
import Dropzone from "react-dropzone";
import { ReactSortable } from "react-sortablejs";
import Item from "./Item";
import Loader from "react-loader";

import "./styles.scss";
import Strings from "utils/strings";

export class ImageGallery extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {};
	}

	filterRenderImage(image: any) {
		const { selectedColor } = this.state;

		if (!selectedColor) return true;
		if (!image.ref) return true;
		if (image.ref && image.ref === selectedColor) return true;

		return false;
	}

	renderImageOnGallery(image: any, index: any, hidePosition: any) {
		const { onDelete } = this.props;
		const currentColor = { color: "#ffffff" };

		const img =
			typeof image === "object" && image !== null ? image.preview : image;

		return (
			<Item
				key={`image_option_${index}`}
				src={img}
				index={index}
				// BACKGROUND COLOR
				color={currentColor && currentColor.color}
				hideColor={true}
				width="auto"
				height="100px"
				borderRadius="5px"
				isNew={!!image.isNew}
				uploadError={image.uploadError || false}
				// ASSOCIATED COLOR
				selectedColor={currentColor && currentColor.color}
				hidePosition={!!hidePosition}
				onDelete={() => onDelete(index)}
				onChooseColor={() => {
					//image.ref = selectedColor || null;
					//this.setState({ images, hasUnsavedFields: true });
				}}
			/>
		);
	}

	render() {
		const { images, onDrop, onChange, onSetList } = this.props;
		const { selectedColor } = this.state;
		const selected = null;

		return (
			<div
				className="image_product_section"
				style={{ backgroundColor: "white", marginBottom: 0, marginTop: 5 }}
			>
				<label className="SingleLabel __required">Images</label>{" "}
				{images?.length > 0 && (
					<ReactSortable
						key={`sortable_images_${!!selected}`}
						id="image_container"
						className="image_container"
						options={{ disabled: !!selected }}
						list={images || []}
						setList={(newImagesList) => {
							onSetList(newImagesList);
							//this.setState({ project: { ...project, images: newImagesList } });
						}}
						// onChange={(order, sortable, evt) => {
						// 	onChange(order, sortable, evt);
						// }}
					>
						{images
							.filter((img: any, index: any) => this.filterRenderImage(img))
							.map((img: any, index: any) =>
								this.renderImageOnGallery(img, index, selectedColor)
							)}
					</ReactSortable>
				)}
				<Dropzone
					className="defaultDropzone"
					onDrop={(files) => onDrop(files)}
					multiple
					accept="image/jpg, image/jpeg, image/png"
				>
					<Loader loaded={!this.state.loadingImages}>
						<div
							style={{ justifyContent: "space-evenly" }}
							className="dropzone_placeholder"
						>
							<em className="moon-frame" />
							<p>Drop here new images</p>
						</div>
					</Loader>
				</Dropzone>
			</div>
		);
	}
}

export default ImageGallery;
