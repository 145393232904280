/**
 *
 * Header
 *
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Icon, Title, Breadcrumb } from 'components';
import './styles.scss';

/* eslint-disable react/prefer-stateless-function */
export class Header extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = {
			open: false,
			quickOptionsOpen: false,
			prevLocation: '',
			startDate: '',
			renewalDate: '',
			period: '',
			isExtended: false,
			openDeleteModal: false,
			dummyOptions: {
				deleteProducts: true,
				deleteCategories: true,
				deleteSegments: true,
				deleteAttributes: true,
				deleteBrands: true,
				deleteBanners: true,
				all: true,
			},
			hasDummy: false,
			showMenuItem: false,
			optionsMenu: 'tablet',
			user: '',
			maintenance: false,
		};

		this.closeDropdown = this.closeDropdown.bind(this);
		this.toggleSidebar = this.toggleSidebar.bind(this);
		this.closeQuickOptions = this.closeQuickOptions.bind(this);
		this.goToProfile = this.goToProfile.bind(this);
		this.logout = this.logout.bind(this);

		this.getSidebarBurger = this.getSidebarBurger.bind(this);
		window.addEventListener('resize', this.getSidebarBurger);
	}

	componentDidMount() {
		this.getSidebarBurger();
	}

	componentDidUpdate() {
		const { prevLocation } = this.state;
		const { location } = this.props.router;

		if (prevLocation !== location.pathname) {
			// @ts-ignore
			// eslint-disable-next-line react/no-direct-mutation-state
			this.state.prevLocation = location;

			// document.title = `${this.props.settings && this.props.settings.profile.shopName}`;
		}
	}

	closeDropdown() {
		this.setState({ open: false });
		document.removeEventListener('click', this.closeDropdown);
	}

	openDropdown() {
		this.setState((state: any) => ({ open: !state.open }));
		document.addEventListener('click', this.closeDropdown);
	}

	closeQuickOptions() {
		this.setState({ quickOptionsOpen: false });
		document.removeEventListener('click', this.closeQuickOptions);
	}

	openQuickOptions() {
		this.setState((state: any) => ({
			quickOptionsOpen: !state.quickOptionsOpen,
		}));
		document.addEventListener('click', this.closeQuickOptions);
	}

	handleToggleAll() {
		const { dummyOptions } = this.state;
		this.setState({
			dummyOptions: {
				...dummyOptions,
				deleteAttributes: dummyOptions.all,
				deleteBrands: dummyOptions.all,
				deleteCategories: dummyOptions.all,
				deleteBanners: dummyOptions.all,
			},
		});
	}

	toggleSidebar() {
		this.props.onToggleSidebar();
	}

	goToProfile() {
		this.props.dispatch(push('/profile'));
	}

	logout() {
		// localStorage.removeItem('token');
		// this.props.dispatch(this.props.logout());
		// this.props.dispatch(push('/'));
	}

	renderBurger() {
		const { sidebarOpen } = this.props;

		return (
			<div className="BurgerContainer" onClick={this.toggleSidebar}>
				{sidebarOpen ? (
					<Icon name="dots-vertical" className="BurgerMenu __bigger" />
				) : (
					<Icon name="Menu" className="BurgerMenu" />
				)}
			</div>
		);
	}

	renderTitle() {
		const { title } = this.props;

		return (
			<React.Fragment>
				<div className="HeaderLeftSection">
					{this.renderBurger()}
					{/* <span className="PageTitle">{title}</span> */}
					<Title title={title} />
				</div>
				<div className="HeaderRightSection" />
			</React.Fragment>
		);
	}

	getSidebarBurger() {
		const clientWidth = document.body.clientWidth;

		if (clientWidth >= 992) {
			this.setState({ showMenuItem: false, optionsMenu: 'laptop' });
			return;
		}

		const optionsMenu = clientWidth >= 600 ? 'tablet' : 'mobile';
		this.setState({ showMenuItem: true, optionsMenu });
	}

	render() {
		const { sidebarOpen } = this.props;

		return (
			<div className="HeaderContainer">
				<div id='AppHeader' className='Header'>
					{this.renderTitle()}
					{/* {partners.length >= 2 ? this.renderPartners(partners, selectedPartner) : null} */}
				</div>
				<Breadcrumb sidebarOpen={sidebarOpen} />
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	router: state.router,
	title: state.title,
	user: state.user,
	language: state.language,
	settings: state.settings,
});

export default connect(mapStateToProps)(Header);
