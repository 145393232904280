/*
 *
 * Staff
 *
 */

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Table, Icon } from 'components';
import { EditSidebar } from 'components/EditSidebar';
import { setLoader, setStaffModal, setTitle, delayedDispatch, setBreadcrumb, updateCrumb } from 'store/actions';
import {  Tooltip, Modal } from 'antd';
import { API, Endpoints } from 'utils/api';
import { toast } from "utils/utils";
import moment from 'moment';
import Strings from 'utils/strings';
import './styles.scss';
import { useResizeColumns } from 'react-table';

const staffStrings = {
	owner: Strings.staff.owner,
	admin: Strings.staff.admin,
} as any;

export class Staff extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			staff: [],
			item: {},
			openConfirmResendInviteModal: false,
		};
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(setTitle(Strings.sidebar.staff));

		delayedDispatch(
            setBreadcrumb(() => {
                return {
                    locations: [
                        {
                          text: Strings.sidebar.staff,
                        //   route: "/staff",
                          icon: "working-briefcase",
                        },
					],
                }
            })
		);

		this.getStaff();
	}

	componentDidUpdate() {
		const { dispatch } = this.props;
        dispatch(updateCrumb());
	}

	async getStaff() {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		try {
			const response = await API.get({
				url: Endpoints.uriStaff(),
			});

			if (response.ok) {
				this.setState({
					staff: response.data.results || [],
				});
			}
		} catch (err) {
			console.log('API Error', err);
		}

		dispatch(setLoader(false));
	}

	onEdit(value: any) {
		const { staff = [] } = this.state;
		const { dispatch, user } = this.props;
		dispatch(
			setStaffModal({
				open: true,
				isMe: value._id === user._id,
				staff: value,
				callback: (user: any) => {
					console.log('staff', staff)
					console.log('user', user)
					const index = staff.findIndex(
						(staff: any) => staff._id === user._id
					);
					if (index !== -1) {
						staff[index] = JSON.parse(JSON.stringify(user));
						this.setState({ staff });
					}
				},
			})
		);
	}

	onDelete = async (id: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.delete({ url: Endpoints.uriStaff(id) }).then(response => {
			if (response.ok && response.data.results.staff) {
				this.setState({ staff: response.data.results.staff, openSidebar: false, item: {} });
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	toogleStaff = async (staff: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.patch({
			url: Endpoints.uriStaff(staff._id),
			data: { isActive: !staff.isActive}
		}).then(response => {
			if (response.ok) {
				this.setState({ staff: response.data.results.staff });
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	resendEmail = async (staff: any) => {
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		await API.post({
			url: Endpoints.uriStaff(`resend-invite/${staff._id}`),
		}).then(response => {
			if (response.ok && response.data.results) {
				this.setState({ staff: response.data.results, openConfirmResendInviteModal: false });
				toast.success(response.data.message);
			} else
				toast.error(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	getStatus(status: any) {
		if (status) {
			return Strings.staff.confirmed;
		}
		return Strings.staff.pending;
	}

	createStaff = async () => {
		const { item, places } = this.state;
		const { dispatch } = this.props;

		dispatch(setLoader(true));

		console.log('places', places)
		console.log('item.place', item.place)
		if (item.place) item.place = places.find((elem: any) => elem.name === item.place)?._id;

		const data = new FormData();
		data.append('name', item.name)
		data.append('role', item.role)
		data.append('email', item.email)
		data.append('photo', JSON.stringify(item.photo))

		await API.post({
			url: Endpoints.uriStaff(),
			data,
		}).then(response => {
			if (response.ok && response.data.results.staff) {
				this.setState({ staff: response.data.results.staff, openSidebar: false, item: {} });
			} else
				toast.success(response.data.message);
		}).catch(err => {
			toast.error(err);
		});

		dispatch(setLoader(false));
	}

	onChange = (field: string, value: any) => {
		const { item, places } = this.state;

		this.setState({ item: { ...item, [field]: value } });
	}

	closeSidebar = () => {
		this.setState({ openSidebar:false });
	}

	openSidebar = (value: any = {}) => {
		this.setState({ openSidebar:true, item: JSON.parse(JSON.stringify(value)) });
	}

	renderDrawer() {
		const { item, placesOptions } = this.state;
		const role = this.props.user?.role
		return (
			<EditSidebar
				title={Strings.sidebar.staff}
				open={this.state.openSidebar}
				onClose={this.closeSidebar}
				onChange={this.onChange}
				onSubmit={this.createStaff}
				defaultValue={this.state.item}
				fields={[
					{
						field: 'photo',
						value: item?.photo,
						type: 'photo',
						name: Strings.fields.photo,
					},
					{
						field: 'name',
						value: item?.name,
						type: 'input',
						name: Strings.fields.name,
						required: true,
					},
					{
						field: 'role',
						value: item?.role,
						type: 'selector',
						name: Strings.profile.role,
						options: ['owner', 'admin'],
						required: true,
					},
					{
						field: 'email',
						value: item?.email,
						type: 'input',
						name: Strings.fields.email,
						required: true,
					},
					{
						version: moment.now(),
					}
				]}
			/>
		);
	}

	renderConfirmResendInvite = () => {
		const { openConfirmResendInviteModal } = this.state;

		return (
			<Modal
				centered
				className="resendInvite"
				style={{ textAlign: 'center' }}
				visible={openConfirmResendInviteModal}
				cancelText={Strings.generic.close}
				okText={Strings.authentication.resendInviteEmail}
				onOk={() => this.resendEmail(openConfirmResendInviteModal)}
				onCancel={() => this.setState({ openConfirmResendInviteModal: false })}
				title={null}
				closable={false}
				bodyStyle={{ minHeight: 420 }}
			>
				<Icon name='paper-plane' style={{ fontSize: '50px' }}></Icon>
				<div className="title">
					{openConfirmResendInviteModal ? Strings.formatString(
						Strings.authentication.confirmResendInviteEmail,
						openConfirmResendInviteModal.name) :
						Strings.authentication.resendInviteEmail}
				</div>
			</Modal>
		);
	}

	renderStatus = (elem: any) => {
		if (elem.value === 'true')
			return (
				<div className="status __confirmed">
					<span>{this.getStatus(true)}</span>
				</div>
			);

		return (
			<Tooltip title={Strings.authentication.resendInviteEmail}>
				<div
					className="status __pending"
					onClick={() => this.setState({ openConfirmResendInviteModal: elem.cell.row.original })}>
					<Icon name="refresh" className="resend" />
					<span>{this.getStatus(false)}</span>
				</div>
			</Tooltip>
		);
	}

	render() {
		const { staff = [] } = this.state;
		const { dispatch, user } = this.props;

		return (
			<React.Fragment>
				<Helmet>
					<title>{Strings.sidebar.staff}</title>
					<meta name='description' content='Description of Staff' />
				</Helmet>
				<Table
					title={{
						icon: "working-briefcase",
						title: Strings.sidebar.staff
					}}
					data={staff}
					columns={[
						{
							Header: Strings.fields.photo,
							id: 'photo',
							accessor: (row: any) => row.photo,
							type: 'image',
							maxWidth: 65,
						},
						{
							Header: Strings.fields.name,
							id: 'name',
							accessor: (row: any) => row.name,
						},
						{
							Header: Strings.fields.email,
							id: 'email',
							accessor: (row: any) => row.email,
						},
						{
							Header: Strings.staff.status,
							id: 'status',
							accessor: (user: any) => String(user.isConfirmed),
							Cell: this.renderStatus,
						},
						{
							Header: Strings.profile.role,
							id: 'role',
							accessor: (row: any) => staffStrings[row.role],
						},
					]}
					filterable
					add={{
						label: String(Strings.formatString(Strings.generic.addNewM, Strings.sidebar.staff.toLowerCase())),
						onClick: () => this.openSidebar(),
					}}
					actions={{
						edit: (original, value) => ({
							disabled: original.role === 'owner',
							onClick: () => this.onEdit(original),
						}),
						remove: (original, value) => ({
							disabled: original.role === 'owner',
							onClick: () => this.onDelete(original._id),
						}),
						toggle: (original: any, value: any) => ({
							disabled: original.role !== 'inspector' &&
									user.role !== 'sysadmin' &&
									user.role !== 'owner' &&
									user.role !== 'admin' &&
									user.role !== 'hr',
							value: original.isActive,
							onChange: () => {
								console.log('original', original)
								console.log('value', value)
								this.toogleStaff(original)
							},
						}),
					}}
				/>
				{this.renderDrawer()}
				{this.renderConfirmResendInvite()}
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state: any) => ({
	user: state.user,
});

export default connect(mapStateToProps)(Staff);
