import React from 'react';
import PropTypes from 'prop-types';
import Strings from 'utils/strings';

/* eslint-disable react/prefer-stateless-function, no-shadow, no-underscore-dangle, no-new, no-restricted-syntax, jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, react/no-array-index-key */
export default class ProductDetail extends React.Component {
	shouldComponentUpdate(nextProps) {
		return true;
	}

	onDelete() {
		const { onDelete } = this.props;

		if (typeof onDelete === 'function') onDelete();
	}

	onChoose(e) {
		const { onChooseColor } = this.props;
		e.preventDefault();
		if (typeof onChooseColor === 'function') onChooseColor(e);
	}

	render() {
		const {
			src,
			index,
			hidePosition,
			hideColor,
			selectedColor,
			isNew,
			color,
			borderRadius,
			width,
			height,
			uploadError,
		} = this.props;
		const style = {};
		const imageStyle = { width, height, borderRadius };

		if (color) style.backgroundColor = color;

		if (selectedColor) imageStyle.borderColor = selectedColor;

		if (!selectedColor) imageStyle.borderWidth = 0;

		return (
			<div className="item_container">
				<span className="position" style={{ opacity: hidePosition ? 0 : 1 }}>
					{index + 1}
				</span>
				<div className="item">
					<img
						alt=""
						draggable="false"
						className={`item_image ${uploadError ? 'error' : ''}`}
						src={src}
						style={imageStyle}
						onClick={e => selectedColor && this.onChoose(e)}
					/>
					<div className="option item_option item_close" onClick={() => this.onDelete()}>
						<em className="moon-close1" />
					</div>
					<div className="item_option_container">
						{!hideColor && (
							<div className="option item_option" style={style} onClick={e => this.onChoose(e)}>
								{!color && <em className="moon-block" />}
							</div>
						)}
					</div>
					{isNew && <div className="item_new">{Strings.generic.newF}</div>}
				</div>
			</div>
		);
	}
}

ProductDetail.propTypes = {
	src: PropTypes.string,
	index: PropTypes.number,
	hidePosition: PropTypes.bool,
	color: PropTypes.string,
	hideColor: PropTypes.bool,
	selectedColor: PropTypes.string,
	onDelete: PropTypes.func,
	onChooseColor: PropTypes.func,
	borderRadius: PropTypes.number,
	width: PropTypes.number,
	height: PropTypes.number,
};
