// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ContentWrapper{background-color:#fff;border-radius:0;padding:10px;box-shadow:0 1px 2px rgba(0,0,0,.05),0 0 0 1px rgba(63,63,68,.1);margin-bottom:10px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
