/* eslint-disable no-restricted-globals */
/*
 *
 * JobOportunityDetail
 *
 */

import React from "react";
import { connect } from "react-redux";
import { delayedDispatch, setBreadcrumb, setLoader, setTitle, updateCrumb } from "store/actions";
import { Form, Col, Row, Input, DatePicker } from "antd";
import { Helmet } from "react-helmet";
import Dropzone from "react-dropzone";
import { Icon, Editor, ContentWrapper } from "components";
import Strings from "utils/strings";
import { API, Endpoints } from "utils/api";
import { toast, translate } from 'utils/utils';
import Compressor from "compressorjs";
import moment from "moment";
import placeholder from "assets/images/placeholders/image.jpg";
import "./styles.scss";
import { push } from "connected-react-router";

export class JobOportunityDetail extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      _active: true,
      image: null,
      title: "",
      language: "pt",
      hasUnsavedFields: false,
      filesToDelete: [],
      error: {},
    };

    this.handleSwitch = this.handleSwitch.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
  }

  async componentDidMount() {
    const { dispatch, match } = this.props;

    dispatch(setLoader(true));

    if (match?.params?.id === 'new') {
      dispatch(setTitle(`${Strings.jobOportunities.single} - ${Strings.jobOportunities.new}`))
    } else {
      const response = await API.get({
        url: Endpoints.uriJobOportunities(match?.params?.id),
      });

      if (response.ok) {
        this.setState({ ...response?.data?.results?.jobOportunities, _active: response?.data?.results?.jobOportunities?.isActive });
        dispatch(setTitle(`${Strings.jobOportunities.single} - ${response?.data?.results?.jobOportunities?.title}`))
      }
    }

    delayedDispatch(
      setBreadcrumb(() => {
        const { hasUnsavedFields, language, _active } = this.state;

        return {
          locations: [
            {
              text: Strings.jobOportunities.single,
              route: "/job-oportunities",
              icon: "text-files",
            },
            {
              text: this.state.title,
              icon: "file",
            },
          ],
          actions: [
            {
							type: 'switch',
							text: Strings.generic.active,
							value: _active,
							onClick: this.handleSwitch,
							small: {
								switch: true,
								text: true,
							},
						},
            {
              type: "language",
              value: language,
              onChange: this.handleLanguageChange,
              className: "BreadcrumbLanguage",
              margin: "left",
            },
            {
              type: "button",
              text: Strings.generic.save,
              onClick: this.handleSave,
              disabled: !hasUnsavedFields,
              className: hasUnsavedFields ? "BreadcrumbButtonSuccess" : "",
              separator: "left",
              isSave: true,
            },
          ],
        };
      })
    );

    dispatch(setLoader(false));
  }

  componentDidUpdate() {
    const { dispatch } = this.props;

    dispatch(updateCrumb());
  }

  isValid() {
    const { language, title, smallDescription, offerDate, location, offerDescription, image } = this.state;
    // image
    return title && smallDescription[language] && moment(offerDate) && location[language] && offerDescription[language];
  }

  async handleSave(event: any) {
    const { match, dispatch } = this.props;
    const { _active, title, smallDescription, offerDate, location, offerDescription, image, filesToDelete } = this.state;

    if (!this.isValid()) {
      return toast.warn(Strings.errors.invalidFields);
    }

    dispatch(setLoader(true))
    
    try {
      const body = new FormData(); 
      body.append("isActive",_active)
      body.append("title",title)
      body.append("smallDescription",JSON.stringify(smallDescription))
      body.append("offerDate",offerDate)
      body.append("location",JSON.stringify(location))
      body.append("offerDescription",JSON.stringify(offerDescription))
      body.append("image",(image && typeof image === "object") ? image.file : image)
      if (filesToDelete.length) {
        body.append("filesToDelete", JSON.stringify(filesToDelete));
      }

      const request = match?.params?.id === 'new' ? API.post : API.put;

      const response = await request({
        url: Endpoints.uriJobOportunities(match?.params?.id === 'new' ? '' : match?.params?.id ),
        data: body,
      });

      if (response.ok) {
				if (match?.params?.id === "new") {
					dispatch(push(`/job-oportunities/${response?.data?.results?.jobOportunity?._id}`));
        } else {
					this.setState({
						...response?.data?.results?.jobOportunity,
            _active: response?.data?.results?.jobOportunity?.isActive,
						filesToDelete: [],
						hasUnsavedFields: false,
					});
        }
			}
    } catch (error) {
        console.log("error",error)
    }

    dispatch(setLoader(false))
  }

  handleLanguageChange(value: any, options?: any) {
    this.setState({ language: value });
  }

  handleSwitch(value: any) {
    this.setState({ _active: value, hasUnsavedFields: true });
  }

  getBase64 = (file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});
	};

  onDrop = (files: any, field: string) => {
		if (!field) return;

		try {
			const file = files[files.length - 1];

			new Compressor(file, {
				mimeType: "image/png",
				success: (result: any) => {
					this.getBase64(result).then((res) => {
						this.setState((state: any) => ({
							[field]: { file: result, preview: res },
              filesToDelete: typeof state[field] === 'string' ? [state[field]] : state.filesToDelete,
							hasUnsavedFields: true,
						}));
					});
				},
			});
		} catch (err) {
			toast.warning(Strings.errors.notSupportedFile);
		}
	};

  render() {
    const { title, offerDescription, location, offerDate, image, smallDescription, language, error } = this.state;
    const { match } = this.props;

    // initialValues={{title, offerDescription, location, offerDate, image, smallDescription, language}}

    return (
      <React.Fragment>
        <Helmet>
          <title>{match?.params?.id === 'new' ? Strings.jobOportunities.new : (title || Strings.jobOportunities.single) }</title>
          <meta name="description" content="Description of Job Oportunity Detail" />
        </Helmet>  
        <ContentWrapper extraStyle={{paddingLeft: "20px", paddingRight: "20px"}}>    
          <Row gutter={[24, 24]}>
            <Col xs={24} lg={12}>
                <label className="SingleLabel">
                  {Strings.fields.image}
                </label>
                <div className="ImageContainer">
                  <div className="Image">
                    <img
                      alt={Strings.fields.image}
                      src={
                        (image?.preview ? image.preview : image) || placeholder
                      }
                    />
                    <div className="ImageOverlay">
                      <Dropzone
                        accept="image/jpg, image/jpeg, image/png"
                        className="ImageOverlayOption"
                        onDrop={(files) => this.onDrop(files, "image")}
                      >
                        <Icon name="pencil-outline" />
                      </Dropzone>
                      <div
                        className={`ImageOverlayOption${
                          Boolean(image) ? "" : " __disabled"
                        }`}
                        onClick={() => {
                          console.log('STATE', image);
                          if(!Boolean(image)) return;
                          this.setState((state: any) => ({
                            image: null,
                            filesToDelete: typeof state.image === 'string' ? [ state.image ] : state.filesToDelete,
                            hasUnsavedFields: true,
                          }));
                        }}
                      >
                        <Icon name="trash" />
                      </div>
                    </div>
                  </div>
                </div>
            </Col>
            <Col xs={24} lg={12}>
              <Row>
                  <label className="SingleLabel __required">
                    {Strings.jobOportunities.title}
                  </label>
                  <Input
                    placeholder={Strings.jobOportunities.single}
                    value={title || ''}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.setState((state:any)=>({
                        title: value,
                        hasUnsavedFields: true,
                        error: {
                          ...state.error,
                          [state.language] : {
                            ...state.error[state.language],
                            title: value ? "" : Strings.errors.fillField
                          }
                        }
                      }));
                    }}
                  />
              </Row>
              <Row>
                <label className="SingleLabel __required">
                  {Strings.fields.date}
                </label>
                <DatePicker
                    placeholder={Strings.fields.date}
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY"
                    value={offerDate ? moment(offerDate) : null}
                    onChange={(e) => {
                      this.setState((state:any)=>({
                        offerDate: e?.format(),
                        hasUnsavedFields: true,
                        error: {
                          ...state.error,
                          [state.language] : {
                            ...state.error[state.language],
                            offerDate: e?.format() ? "" : Strings.errors.fillField
                          }
                        }
                      }));
                    }}
                  />
              </Row>
              <Row>
                <label className="SingleLabel __required">
                  {Strings.jobOportunities.location}
                </label>
                <Input
                    placeholder={Strings.jobOportunities.location}
                    value={location?.[language]  || ''}
                    onChange={(e: any) => {
                      const { value } = e.target;
                      this.setState((state:any) => ({
                        location: { ...state.location, [state.language]: value },
                        hasUnsavedFields: true,
                        error: {
                          ...state.error,
                          [state.language] : {
                            ...state.error[state.language],
                            location: value ? "" : Strings.errors.fillField
                          }
                        }
                      }));
                    }}
                  />
              </Row>
              <Row>
                <label className="SingleLabel __required">
                  {Strings.fields.description}
                </label>
                <Input
                  placeholder={Strings.fields.description}
                  value={smallDescription?.[language] || ''}
                  onChange={(e: any) => {
                    const { value } = e.target;
                    this.setState((state:any) => ({
                      smallDescription: { ...state.smallDescription, [state.language]: value },
                      hasUnsavedFields: true,
                      error: {
                        ...state.error,
                        [state.language] : {
                          ...state.error[state.language],
                          smallDescription: value ? "" : Strings.errors.fillField
                        }
                      }
                    }));
                  }}
                />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={24}>
              <label className="SingleLabel __required">
                {Strings.jobOportunities.content}
              </label>
              <Editor
                key={`editor_${language}_opportunity`}
                required
                init={{ height: 500 }}
                value={offerDescription?.[language] || ''}
                onChange={(value: any) => {
                  this.setState((state: any) => ({
                    offerDescription: {
                      ...state.offerDescription,
                      [state.language]: value,
                    },
                    hasUnsavedFields: true,
                    error: {
                      ...state.error,
                      [state.language] : {
                        ...state.error[state.language],
                        offerDescription: value ? "" : Strings.errors.fillField
                      }
                    }
                  }));
                }}
              />
            </Col>
          </Row>
        </ContentWrapper>  
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps)(JobOportunityDetail);
