// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DashboardCard{border-radius:4px;padding:0}.DashboardMainChart{display:flex;width:100%;max-width:100%;height:500px}.DashboardMainChartTitle{display:flex;align-items:center;margin-left:60px;margin-bottom:25px}.DashboardMainChartTitle span{color:#333;font-size:20px}.DashboardMainChartTitle em{font-size:28px;margin-right:10px}.DashboardCardIcon{font-size:20px}", ""]);
// Exports
exports.locals = {
	"primaryColor": "#a86206",
	"lightPrimary": "#a3681b",
	"secondaryColor": "#694617",
	"lightSecondary": "#ad7d3e"
};
module.exports = exports;
